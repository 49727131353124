/* global angular:true,  _:true */

import moment from 'moment';
import Logger from 'js-logger';
import Cookies from 'cookies-js';
import { isNull } from 'lodash';

import { RelationalBase, relationship } from 'helioscope/app/relational';
import { uniqueId } from 'helioscope/app/utilities/helpers';
import { Authenticator } from 'helioscope/app/utilities/ng';
import { INITIAL_TOU_DATE, FINAL_TOU_DATE, TOU_YEAR_END } from 'helioscope/app/constants';
import { Team } from './teams';
import { Logo } from './logos';
import { Subscription } from './subscriptions';

const logger = Logger.get('user/resources');

export class User extends RelationalBase {
    static relationName = 'User';

    fullName() {
        return `${this.first_name} ${this.last_name}`;
    }

    /**
     * whether a user can legally access the system
     */
    isActivated() {
        if (this.current_period_end == null) {
            return false;
        }

        return (this.activated && !this.isExpired());
    }

    isExpired() {
        return this.status === 'expired_trial' || this.status === 'expired_subscription';
    }

    isOnTrial() {
        return this.status === 'trial';
    }

    activeSubscription() {
        if (!this.subscription) {
            return false;
        }

        return this.subscription.is_active;
    }

    useCustomerBranding() {
        return this.default_logo_id && _.get(this, 'preferences.features.customer_branding', false);
    }

    hasFeature(feature) {
        return this.active_features.includes(feature);
    }

    hasFinancialsAccess() {
        return this.role.can_view_financials || this.hasFeature('enable_financials_access');
    }

    hasSingleAxisTrackersAccess() {
        return this.role.can_use_single_trackers || this.hasFeature('enable_single_trackers');
    }

    hasP90WeatherVariabilityAccess() {
        return this.role.can_view_p90_weather_variability && this.hasFeature('enable_p90')
    }

    canManageBilling() {
        return this.role.can_manage_billing || Authenticator.user().is_admin;
    }

    removeFeature(feature) {
        delete this.preferences.features[feature];
    }

    async hasUsersProjectLimitReached() {
        const teamLimitsAndUsage = await Team.limitsAndUsage({ team_id: this.team_id }).$promise;
        let projectLimitsAndUsage = null;

        if (this.isOnTrial()) {
            projectLimitsAndUsage = teamLimitsAndUsage.trial_limits;
        } else if (this.activeSubscription()) {
            projectLimitsAndUsage = teamLimitsAndUsage.subscription_limits;
        }

        return !isNull(projectLimitsAndUsage) && !isNull(projectLimitsAndUsage.project_limit) && projectLimitsAndUsage.project_count >= projectLimitsAndUsage.project_limit;
    }

    get sessionId() {
        if (!this._sessionId) {
            this._sessionId = Cookies.get('flsession_id');
        }

        // shouldn't happen
        if (!this._sessionId) {
            this._sessionId = uniqueId();

            logger.error('Unable to find session cookie. Generating one instead.');
        }

        return this._sessionId;
    }

    get now() {
        return (this.latest_subscription && this.latest_subscription.now()) || moment();
    }

    get shouldShowClickwrap() {
        const now = this.now.toDate();
        const hasActiveSubscription = this.subscription && this.subscription.is_active;
        const isNewTOUActive = (!this.tou_snooze_until || this.tou_snooze_until.isBefore(now)) &&
            now > INITIAL_TOU_DATE;
        return (
            hasActiveSubscription &&
            isNewTOUActive &&
            !this.tou_accepted
        );
    }

    get finalTouDate() {
        return (
            this.subscription &&
            this.subscription.plan_type === 'year' &&
            this.subscription.current_period_end &&
            this.subscription.current_period_end.isAfter(FINAL_TOU_DATE) &&
            this.subscription.current_period_end.isBefore(TOU_YEAR_END)
                ? this.subscription.current_period_end.clone().subtract(1, 'days').toDate()
                : FINAL_TOU_DATE
        );
    }

    get shouldShowFinalClickwrap() {
        const now = this.now.toDate();

        return this.shouldShowClickwrap && now > this.finalTouDate;
    }

    get shouldShowSnoozeableClickwrapEverywhere() {
        const now = this.now.toDate();
        const snoozeableEverywhereDate = new Date(this.finalTouDate.getTime());
        snoozeableEverywhereDate.setDate(snoozeableEverywhereDate.getDate() - 30);

        return this.shouldShowClickwrap && !this.shouldShowFinalClickwrap && now > snoozeableEverywhereDate;
    }

    get shouldShowSnoozeableClickwrapLogin() {
        const globalDialogOpen = this.shouldShowFinalClickwrap || this.shouldShowSnoozeableClickwrapEverywhere;

        return this.shouldShowClickwrap && !globalDialogOpen;
    }

    get hasIndependentTiltAccess() {
        return this.role.can_use_independent_tilt || this.hasFeature('enable_independent_tilt_override');
    }

    static computeLocalizationProps() {
        // "navigator" is a global variable available in the browser.
        // eslint-disable-next-line no-undef
        const locale = (navigator.languages != null) ? navigator.languages[0] : navigator.language;
        return {
            time_zone_offset: -(new Date().getTimezoneOffset()) / 60,
            time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
            // navigator.language(s) uses dash ("-") to delimit locale. server expects underscore ("_") delimited.
            locale: locale.replace('-', '_'),
        };
    }
}

User.configureRelationships({
    default_logo: relationship(Logo, { id: 'default_logo_id' }),
    team: relationship(Team, { backref: 'users' }),
    current_period_end: (rawTime) => moment(rawTime),
    created: (rawTime) => moment(rawTime),
    tou_accepted: (rawTime) => moment(rawTime),
    tou_declined: (rawTime) => moment(rawTime),
    tou_snooze_until: (rawTime) => moment(rawTime),
    subscription: (rawSub) => new Subscription(rawSub),
    latest_subscription: (rawSub) => new Subscription(rawSub),
});

User.createEndpoint('/api/users/:email', {},
    { query: { method: 'GET', isArray: true, url: '/api/users/' },
        update: { method: 'PUT', isArray: false, params: { email: '@email' } },
        activate: { url: '/api/users/activate/:auth_token', method: 'PUT', isArray: false },
        resendAuthToken: { url: '/api/users/:email/resend_token', method: 'PUT', isArray: false },
        updatePreferences: { method: 'PUT', isArray: false, params: { email: '@email', action: 'update_preferences' } },
        updateRenderPreferences: {
            method: 'PUT', isArray: false, params: { email: '@email', action: 'update_renders' },
        },
        updateDesignerPreferences: {
            method: 'PUT', isArray: false, params: { email: '@email', action: 'update_designer' },
        },
        acceptTou: { method: 'PUT', isArray: false, params: { email: '@email', action: 'accept_tou' } },
        declineTou: { method: 'PUT', isArray: false, params: { email: '@email', action: 'decline_tou' } },
        snoozeTou: { method: 'PUT', isArray: false, params: { email: '@email', action: 'snooze_tou' } },
        updatePassword: { method: 'PUT', isArray: false, params: { email: '@email', action: 'change_password' } },
        login: { url: '/api/users/login', method: 'POST', isArray: false, ignoreAuthModule: true },
        logout: { url: '/api/users/logout', method: 'POST', isArray: false },
        hasSSO: { url: '/api/users/:email/has_sso', method: 'GET', isArray: false, params: { email: '@email' } },
        resetPassword: { url: '/api/users/reset', method: 'POST', isArray: false },
        extendTrial: {
            url: '/api/users/:email/extend_trial', method: 'PUT', isArray: false, params: { email: '@email' },
        },
        trialExtensions: { url: '/api/users/trial_extensions', method: 'GET', isArray: true },
        trialExtensionCount: { url: '/api/users/:email/trial_extension_count', method: 'GET', params: { email: '@email' } },
        acquireSessionLock: { url: '/api/users/acquire_session_lock', method: 'PUT' },
    });
