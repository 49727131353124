/* tslint:disable:variable-name */
import { compact } from 'lodash';
import { BaseClass, ReduxEndpoint } from 'reports/utils/api';

import { WeatherDataset, schemaObj as wdSchemaObj } from './weather_dataset';
import { schema } from './schema';
import { IAPIQueryOpts } from './types';
import { ILatLong } from './common';

class WeatherSource extends BaseClass {
    weather_source_id: number;
    name: string;
    description: string;
    city: string;
    state: string;
    country: string;
    source: string;
    source_type?: WeatherSourceType;
    location: ILatLong;
    station_class?: StationClassType;
    team_id: number;
    url: string;
    external_id: string;
    elevation: number;
    dataset_name: string;
    time_zone_offset: number;
    ghi_year_to_year_variability?: number;

    weather_datasets: WeatherDataset[];

    distance?: number; // Optional Number if queried from a project

    toString() {
        const inParens = compact([
            this.source_type,
            this.station_class && STATION_CLASS_TYPES[this.station_class],
        ]).join(', ');

        const maybeSource = this.source ? `, ${this.source}` : ``;

        return `${this.name}${maybeSource}${inParens && ` (${inParens})`}`;
    }

    get latLong() {
        return `(${this.location.latitude.toFixed(2)}, ${this.location.longitude.toFixed(2)}) UTC ${
            this.time_zone_offset
        }`;
    }

    get locationString() {
        return this.city
            ? `${this.city}${this.state ? ` ${this.state}` : ``}${this.country ? ` ${this.country}` : ``}`
            : this.latLong;
    }
}

const schemaObj = schema.addObject(WeatherSource, 'weather_source');

const { backrefSelector: weatherDatasets } = wdSchemaObj.addRelationship('weather_source', schemaObj, {
    backref: 'weather_datasets',
});

interface IWeatherSourceQuery extends IAPIQueryOpts {
    project_id?: number;
    load_datasets?: boolean;
    only_tmy_datasets?: boolean;
}

const endpoint = ReduxEndpoint.fromSchema('/api/weather_sources/', schemaObj);

interface IWeatherSourceUploadForm {
    file: File;
    filetype: string;
}
export interface IWeatherSourceSaveForm {
    weather_source_id: number;
    name: string;
    source: string;
    description: string;
    dataset_name: string;
}

const api = {
    // The index endpoint uses ReduxEndpoint.PassThroughConfig() to avoid processing large amounts
    // of data through the schema object. This allows for timely loading of the weather sources library.
    index: endpoint.index<IWeatherSourceQuery>('', {
        ...ReduxEndpoint.PassThroughConfig(),
        selector: (_state, respData) => respData.map((ws) => new WeatherSource(ws)),
    }),
    get: endpoint.get<{ weather_source_id: number | string }>('{weather_source_id}'),
    save: endpoint.put<IWeatherSourceSaveForm>('{weather_source_id}'),
    upload: endpoint.post<IWeatherSourceUploadForm, undefined, WeatherSource>('upload'),
};

const selectors = {
    weatherDatasets,
    byId: schemaObj.selectById,
    all: schemaObj.selectAll,
};

type WeatherSourceType = keyof typeof WEATHER_SOURCE_TYPES;

const WEATHER_SOURCE_TYPES = {
    tmy3: 'NREL Typical Meteo Year (tmy3)',
    tmy2: 'NREL Typical Meteo Year (tmy2)',
    epw: 'DOE Energy Plus (epw)',
    prospector: 'Prospector',
    psm3: 'PSM v3',
    swera: 'SWERA',
    meteonorm: 'Meteonorm 7',
    meteonorm_v8: 'Meteonorm 8',
    custom: 'Custom',
};

// https://nsrdb.nrel.gov/data-sets/us-data
// ctrl+f physical solar model
const WEATHER_CONSTS = {
    PsmLatZero: -179.99,
    PsmLonZero: -179.98,
    PsmRes: 0.04,
};

type StationClassType = keyof typeof STATION_CLASS_TYPES;

const STATION_CLASS_TYPES = {
    i: 'I',
    ii: 'II',
    iii: 'III',
};

export { WeatherSource, WeatherSourceType, WEATHER_SOURCE_TYPES, WEATHER_CONSTS, schemaObj, api, selectors };
