import Logger from 'js-logger';
import { toPairs } from 'lodash';

import * as analytics from 'helioscope/app/utilities/analytics';
import { Messager } from 'helioscope/app/utilities/ng';
import { readAsDataURL, getBlobFromCanvas, loadImage, downloadURL } from 'helioscope/app/utilities/io';
import { fetchJSON } from 'helioscope/app/utilities/relational/resource';

import * as pusher from 'helioscope/app/utilities/pusher';

const logger = Logger.get('pdf');

export async function addCanvasImageOverlays(sourceElement) {
    const canvases = angular.element(sourceElement).find('canvas');

    const canvasImages = [];

    for (const canvas of canvases) {
        const blob = await getBlobFromCanvas(canvas);
        const url = URL.createObjectURL(blob);
        const image = await loadImage(url);

        angular.element(image).css(Object.assign({}, canvas.style, { width: '100%' }));
        canvas.parentNode.appendChild(image);
        angular.element(canvas).css({ display: 'none' });

        canvasImages.push({ canvas, image });
    }
    return () => {
        for (const { canvas, image } of canvasImages) {
            angular.element(canvas).css({ display: image.style.display });
            canvas.parentNode.removeChild(image);
        }
    };
}

const mod = angular.module('ngPDF', []);

mod.directive('pdf', ['$location', function ngPDF($location) {
    return {
        restrict: 'EA',
        scope: { filename: '@', pdfTitle: '@' },

        controller($scope) {
            this.download = async () => {
                const notification = Messager.load('Generating PDF');
                const resp = await fetchJSON(
                    '/api/render/?async=true&legacy-mode=true',
                    {
                        method: 'POST',
                        body: {
                            /* only pass the path, not the url, so we don't accidentally pass mangled query params */
                            url: `${$location.path()}/print`,
                            /* we also need to know whether the collapsable report sections should be open */
                            params: { collapseElements: $('.uncollapse-print.collapse').length > 0 },
                        },
                    }
                );
                notification.progress({ icon: 'fa fa-spinner fa-spin', text: '<b>Creating PDF</b>' });

                const { channel } = resp.data;
                pusher.promiseFromChannel(channel)
                    .then((result) => {
                        const downloadUrl = result.download_url;
                        downloadURL(downloadUrl);
                        notification.success(
                            `<a href="${downloadUrl}" target="_blank">PDF Created Successfully</a>`,
                            { delay: 5000 },
                        );
                    })
                    .catch((err) => {
                        logger.warn(`Error rendering pdf ${$scope.filename}/${channel}:`, err);
                        notification.error('Error generating PDF');
                    });
            };
        },
    };
}]);

mod.directive('pdfButton', ['$rootScope', ($rootScope) => ({
    restrict: 'A',
    require: '^?pdf',
    scope: {
        design: '=?'
    },
    link: (scope, element, attrs, pdfCtrl) => {
        element.click((evt) => {
            evt.preventDefault();
            pdfCtrl.download();

            const teamId = $rootScope.user().team_id;
            const projectId = scope.design?.project_id;
            const designId = scope.design?.design_id;

            analytics.track('project-downloaded-production_report', {
                team_id: teamId,
                project_id: projectId,
                design_id: designId,
            });
        });
    },
})]);

class PdfSourceController {
    constructor($scope, $rootScope, $timeout, $element) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.$element = $element;

        window.onBeforeNgPdfRender = (params) => this.onBeforeNgPdfRender(params);
    }

    async onBeforeNgPdfRender(params) {
        this.$rootScope.$broadcast('pdf:prerender');
        $('body').addClass('pdf');
        this.$rootScope.$broadcast('pdf:resize');
        // yield to give elements a chance to resize based on the event broadcast
        await this.$timeout();

        await addCanvasImageOverlays(this.sourceElement);

        // disable clickable links - for some reason puppeteer's pdf printer doesn't always disable them
        $('a.clickable').removeAttr('href');
        $('a.clickable').removeClass('clickable');

        if (!params.collapseElements) {
            // force-unhide collapsed report elements
            $('.uncollapse-print').removeClass('collapse').removeAttr('style');
        }

        // the PDF renderer grabs its title property from the actual page title, so put the desired PDF title there
        document.title = this.$scope.pdfTitle;

        return {
            type: 'document',
            filename: this.$scope.filename,
            pdfTitle: this.$scope.pdfTitle,
        };
    }
}

mod.directive('pdfSource', () => ({
    restrict: 'E',
    scope: { filename: '@', pdfTitle: '@' },
    controller: PdfSourceController,
}));
