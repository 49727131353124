import TimeMe from 'timeme.js';
import { DESIGNER_METRICS_KEY } from 'reports/modules/project/utils/designerMetrics.ts';

// Timeout to use before starting to detect a user's inactivity
const IDLE_TIMEOUT = 30;

/**
 * Initializes a timer for tracking time spent on a specific page
 * @param {string} pageName - The name of the page to track
 * @param {number} [idleTimeout=5] - The number of seconds before considering the user idle
 */
export const initializePageTimer = (pageName, idleTimeout = IDLE_TIMEOUT) => {
    TimeMe.initialize({
        currentPageName: pageName,
        idleTimeoutInSeconds: idleTimeout,
    });
    TimeMe.startTimer(pageName);
};

/**
 * Stops the timer for a specific page and returns the time spent
 * @param {string} pageName - The name of the page to stop tracking
 * @returns {number} The time spent on the page in seconds
 */
export const stopPageTimer = (pageName) => {
    TimeMe.stopTimer(pageName);
    const timeSpent = TimeMe.getTimeOnPageInSeconds(pageName);
    TimeMe.resetRecordedPageTime(pageName);
    return timeSpent;
};

/**
 * Updates the time spent on a specific design in localStorage
 * @param {number|string} projectId - The ID of the project
 * @param {number|string} designId - The ID of the design
 * @param {number} timeSpent - The time spent in seconds to add to the existing total
 */
export const updateDesignTimeInLocalStorage = (projectId, designId, timeSpent) => {
    if (!projectId || !designId || !timeSpent) return;

    // Get existing metrics from local storage
    const existingMetrics = JSON.parse(window.localStorage.getItem(DESIGNER_METRICS_KEY) || '{"projects":{}}');

    // Check if this project/design combination exists
    if (
        !existingMetrics.projects[projectId] ||
        !existingMetrics.projects[projectId].designs ||
        !existingMetrics.projects[projectId].designs[designId]
    ) {
        console.warning(`Project ${projectId} with design ${designId} not found in local storage - skipping update`);
        return;
    }

    // Update existing time
    const project = existingMetrics.projects[projectId];
    const design = project.designs[designId];
    design.timeSpent = (design.timeSpent || 0) + timeSpent;

    // Save back to localStorage
    window.localStorage.setItem(DESIGNER_METRICS_KEY, JSON.stringify(existingMetrics));
};
