import * as analytics from 'helioscope/app/utilities/analytics';

import {
    createUniqueDescription,
    registerDebugProperty,
    isEventFromTextInput,
    KEY,
    isMacLike,
    isKeyComboPressed,
} from 'helioscope/app/utilities/helpers';
import { Messager, Authenticator, $document, $filter, $modal, $state, $rootScope } from 'helioscope/app/utilities/ng';
import { user } from 'helioscope/app/users';
import { checkWebGL } from 'helioscope/app/apollo/GLRenderer';
import { lidarEnabled } from 'helioscope/app/utilities/lidar/util';
import { ParcelsDataFetcher } from './parcels';
import { Design } from './Design';
import { DesignActionsMixin } from './actions';
import { LOAD_TOAST_MESSAGES, SOURCE_CFG } from '../apollo/LidarHelper';
import { FieldSegment } from './field_segment';
import { Keepout } from './keepout';
import { EntityPremade } from './premade';
import { DESIGNER_CONSTANTS } from './utils/constants';
import { bulkActions } from './BulkActionsMixin';

export class DesignCtrl extends DesignActionsMixin {
    viewMode = '3D';

    constructor(design, dispatcher, $scope, paywallDlg) {
        'ngInject';

        super(dispatcher, design);
        this.dispatcher = dispatcher;
        this.design = design;
        this.scope = $scope;
        this.paywallDlg = paywallDlg;
        this.team_id = $rootScope.user().team_id;
        this.userOnTrial = $rootScope.user().isOnTrial();
        this.enableLidar = lidarEnabled();
        this.lidarStatus = { loading: true, available: false };
        this.lidarUnsub = [];
        this.showImageryDropDown = false;
        this.layersDropdownIsOpen = false;
        this.imageryDropDownCloseFunction = null;
        this.canUseParcels = $rootScope.user().role.can_use_parcels;
        this.isParcelVisible = false;

        analytics.track('designer.webgl_compat', {
            webgl: checkWebGL(),
            design_id: this.design.design_id,
            project_id: this.design.project.project_id,
            team_id: $rootScope.user().team_id,
        });

        this.stateHandler = dispatcher.stateHandler;

        if ($state.is('designer.design')) {
            $state.go('designer.design.field_segments');
        }

        if (user.hasFeature('admin_debug')) {
            registerDebugProperty('designer', (text) => {
                dispatcher.publish('designerConsoleCommand', { text });
            });
        }

        this.init($scope);
    }

    init($scope) {
        if (this.canUseParcels) {
            this.parcelDataFetcher = new ParcelsDataFetcher(this.design.project.location);
            this.parcelDataFetcher.fetchParcelsData();
        }

        $scope.$on('$destroy', () => {
            registerDebugProperty('designer', null);
        });

        this.dispatcher.onRendererReady(() => {
            this.createDocumentListeners($scope);
            if (this.enableLidar) {
                this.createLidarListeners($scope);
            }
        });
    }

    toggleLayersDropdown() {
        this.layersDropdownIsOpen = !this.layersDropdownIsOpen;
    }

    toggleParcel(e) {
        e.stopPropagation();
        this.isParcelVisible = !this.isParcelVisible;

        this.parcelDataFetcher.parcels.forEach((parcel) => {
            parcel.isVisible = !parcel.isVisible;
            this.dispatcher.renderer.renderParcel(parcel);
        });
    }

    closeImageryDropdown() {
        if (this.showImageryDropDown) {
            this.showImageryDropDown = false;
            this.scope.$apply();
        }
        this.cancelCloseImageryDropDown();
    }

    scheduleCloseImageryDropDown() {
        this.cancelCloseImageryDropDown();
        if (this.showImageryDropDown) {
            this.imageryDropDownCloseFunction = _.throttle(this.closeImageryDropdown, 1200, {
                leading: false,
                trailing: true,
            });
            this.imageryDropDownCloseFunction();
        }
    }

    cancelCloseImageryDropDown() {
        if (this.imageryDropDownCloseFunction) {
            this.imageryDropDownCloseFunction.cancel();
            this.imageryDropDownCloseFunction = null;
        }
    }

    toggleImageryDropDown() {
        this.showImageryDropDown = !this.showImageryDropDown;
    }

    toggleImagerySource(name) {
        this.dispatcher.renderer.setImagerySource(name);
    }

    zoom(zoomFactor) {
        this.dispatcher.renderer.zoomCenter(zoomFactor);
    }

    createLidarListeners($scope) {
        this.lidarUnsub.push(
            this.dispatcher.renderer.lidarHelper.subscribe('lidarLoadStart', () => {
                const dataSource = this.dispatcher.renderer.design.lidarSettings.data_source;
                const dataSourceName = SOURCE_CFG[dataSource].fullName;
                this.lidarStatus.loading = true;
                if ($scope.notification) {
                    $scope.notification.close();
                }
                $scope.notification = Messager.load('Loading '.concat(dataSourceName));
                $scope.notification.progress({
                    icon: 'fa fa-spinner fa-spin',
                    text: 'Loading '.concat(dataSourceName),
                });
                $scope.$apply();
            }),
        );

        this.lidarUnsub.push(
            this.dispatcher.renderer.lidarHelper.subscribe(
                'lidarLoadEnd',
                (_lidarHelper, { success, status, dataSourceName }) => {
                    const max = $filter('hsDistance')(this.dispatcher.renderer.lidarHelper.MAX_BOUNDS_SIZE_METERS, 0);
                    const getMessage = LOAD_TOAST_MESSAGES[status];
                    const message = getMessage({ max, dataSourceName });
                    if (success) {
                        $scope.notification.success(message);
                    } else {
                        $scope.notification.error(message);
                    }
                    this.lidarStatus.available = success;
                    this.lidarStatus.loading = false;
                    $scope.$apply();
                },
            ),
        );

        $scope.$on('$destroy', () => {
            while (this.lidarUnsub.length) {
                const unsub = this.lidarUnsub.pop();
                unsub();
            }

            if ($scope.notification) {
                $scope.notification.close();
            }
        });
    }

    openLidarPaywall() {
        // track paywall click
        analytics.track('paywall.open', {
            referrer: 'floating_button',
            modal_name: 'lidar',
        });

        // open paywall modal
        this.paywallDlg();
    }

    triggerLidarLoad() {
        this.dispatcher.renderer.lidarHelper.updateData(true);
    }

    isLidarVisible() {
        return (
            this.dispatcher.renderer &&
            this.dispatcher.renderer.lidarHelper &&
            this.dispatcher.renderer.lidarHelper.getVisibility()
        );
    }

    isLidarUnavailable() {
        return this.enableLidar && !this.lidarStatus.available && !this.lidarStatus.loading;
    }

    toggleLidar(e) {
        e.stopPropagation();
        const newVisibility = !this.dispatcher.renderer.lidarHelper.getVisibility();
        analytics.track('lidar.toggleLidar', {
            toggle: newVisibility ? 'on' : 'off',
            project_id: this.design.project_id,
            design_id: this.design.design_id,
            team_id: $rootScope.user().team_id,
        });
        this.dispatcher.renderer.lidarHelper.setVisibility(newVisibility);
    }

    updateDisplayMode(mode) {
        analytics.track('lidar.updateDisplayMode', {
            mode,
            project_id: this.design.project_id,
            design_id: this.design.design_id,
            team_id: $rootScope.user().team_id,
        });
        this.dispatcher.stateHandler.updateQueue.schedule(this.design);
        this.dispatcher.renderer.lidarHelper.forceRedraw();
    }

    trackLidarSettingsOpen() {
        analytics.track('lidar.openAdvSettings', {
            referrer: 'floating menu',
            project_id: this.design.project_id,
            design_id: this.design.design_id,
            team_id: $rootScope.user().team_id,
        });
    }

    createDocumentListeners($scope) {
        // can't use dynamic name based on constructor because it will collide with other
        // constructor names after minification in production
        const keydown = 'keydown.DesignCtrl';

        $document.on(keydown, (evt) => {
            if (isEventFromTextInput(evt)) {
                return;
            }

            const systemModifier = isMacLike() ? { meta: true } : { ctrl: true };
            const isUndoPressed = isKeyComboPressed({ event: evt, modifiers: systemModifier, keyCode: KEY.z });
            const isRedoPressed = isKeyComboPressed({ event: evt, modifiers: systemModifier, keyCode: KEY.y });
            const isCopyPressed = isKeyComboPressed({ event: evt, modifiers: systemModifier, keyCode: KEY.c });
            const isPastePressed = isKeyComboPressed({ event: evt, modifiers: systemModifier, keyCode: KEY.v });
            const isEscPressed = evt.keyCode === KEY.esc || evt.key === 'Escape' || evt.keyCode === 27;
            const isBackspaceOrDeletePressed =
                [KEY.BACKSPACE, KEY.DELETE].includes(evt.keyCode) || ['Backspace', 'Delete'].includes(evt.key);
            const isRotatePressed = isKeyComboPressed({ event: evt, modifiers: systemModifier, keyCode: KEY.r });

            if (!this.dispatcher.areShortcutsDisabled) {
                if (isUndoPressed) {
                    evt.preventDefault();
                    this.stateHandler.undo();
                    analytics.trackKeyboardAction('designer-shortcut-undo', this.design, $rootScope.user());
                    return;
                }

                if (isRedoPressed) {
                    evt.preventDefault();
                    this.stateHandler.redo();
                    analytics.trackKeyboardAction('designer-shortcut-redo', this.design, $rootScope.user());
                }

                if (isCopyPressed) {
                    evt.preventDefault();
                    this.dispatcher.copySelectedEntitiesToClipboard();
                    analytics.trackKeyboardAction('designer-shortcut-copy', this.design, $rootScope.user());
                }

                if (isPastePressed) {
                    evt.preventDefault();
                    this.dispatcher.activatePasteMode();
                    analytics.trackKeyboardAction('designer-shortcut-paste', this.design, $rootScope.user());
                }

                if (isEscPressed) {
                    evt.preventDefault();
                    if (this.dispatcher.isOnPasteMode) {
                        this.dispatcher.deactivatePasteMode();
                    } else if (this.dispatcher.selectedEntity) {
                        this.dispatcher.deselectEntity();
                    }
                }

                if (isBackspaceOrDeletePressed) {
                    evt.preventDefault();
                    this.deleteSelectedEntities();
                }
            }

            const shouldActivateRotate = () =>
                this.dispatcher.selectedEntities.size > 0 &&
                !(
                    this.dispatcher.selectedEntities.size === 1 &&
                    this.dispatcher.selectedEntity instanceof EntityPremade
                );
            const getRotateEntityType = () => {
                if (this.dispatcher.selectedEntities.size === 1) {
                    if (this.dispatcher.selectedEntity instanceof FieldSegment) {
                        return 'field_segment';
                    } else {
                        return 'keepout';
                    }
                } else {
                    return 'mix';
                }
            };

            if (isRotatePressed) {
                if (shouldActivateRotate()) {
                    evt.preventDefault();
                    this.dispatcher.onRendererReady(() => {
                        this.dispatcher.renderer.activateInteractTool({
                            tool: 'InteractToolRotation',
                        });
                    });
                    analytics.trackRotateKeyboardAction(this.design, $rootScope.user(), getRotateEntityType());
                }
            }
        });

        $scope.$on('$destroy', () => {
            $document.off(keydown);
        });
    }

    changeViewMode(mode) {
        this.viewMode = mode;
    }

    rename() {
        const { design, dispatcher } = this;
        const modalInstance = $modal.open({
            templateUrl: require('helioscope/app/designer/partials/designer.design.rename.modal.html'),
            size: 'md',
            controller() {
                this.description = design.description;
                this.save = () => modalInstance.close(this.description);
            },
            controllerAs: 'ctrl',
        });

        return modalInstance.result.then((desc) => {
            dispatcher.createSinglePropertyChange({
                resource: design,
                path: 'description',
                oldVal: design.description,
                newVal: desc,
                loadMessage: `Rename to ${desc}`,
                rollbackMessage: 'Undo rename',
            });
        });
    }

    getKeyboardShortcutString(action) {
        if (isMacLike()) {
            return action === 'undo'
                ? 'Undo <span style="color: grey;">&#x2318; + Z</span>'
                : 'Redo <span style="color: grey;">&#x2318; + Y</span>';
        } else {
            return action === 'undo'
                ? 'Undo <span style="color: grey;">Ctrl + Z</span>'
                : 'Redo <span style="color: grey;">Ctrl + Y</span>';
        }
    }

    onSideMenuClick() {
        this.dispatcher.renderer.exitRotateTool();
    }
}

export class DesignerLidarSettingsCtrl {
    constructor($scope, dispatcher, design) {
        'ngInject';

        this.$scope = $scope;
        this.dispatcher = dispatcher;
        this.design = design;
        this.stateHandler = dispatcher.stateHandler;
        this.unsubCallbacks = [];
        this.$scope.isLidarLoading = false;
        this.$scope.isLidarAvailable = false;
        this.$scope.isLidarVisible = false;
        this.$scope.editLidarOffsets = {
            editLidarOffsetX: 0,
            editLidarOffsetY: 0,
            editLidarOffsetZ: 0,
        };
        this.$scope.inOffsetEditMode = false;
        if (this.dispatcher.renderer && this.dispatcher.renderer.lidarHelper) {
            this.setUpStateAndCallbacks(this.dispatcher.renderer);
        } else {
            this.dispatcher.subscribe('rendererUpdated', (_dispatcher, { renderer }) => {
                this.setUpStateAndCallbacks(renderer);
            });
        }
    }

    hasLidarAccess() {
        return lidarEnabled();
    }

    setUpStateAndCallbacks(renderer) {
        if (renderer.lidarHelper) {
            this.$scope.dataSource = { source: renderer.design.lidarSettings.data_source };
            this.$scope.isLidarLoading = renderer.lidarHelper.getLidarLoading();
            this.$scope.isLidarAvailable = renderer.lidarHelper.hasLidarData();
            this.$scope.isLidarVisible = renderer.lidarHelper.getVisibility();
            this.registerCallbacks(renderer);
        }
    }

    registerCallbacks(renderer) {
        this.lidarHelper = renderer.lidarHelper;

        this.$scope.$on('$destroy', () => {
            // If lidarHelper is already destroyed, don't need to exit offset edit mode
            if (this.dispatcher.renderer.lidarHelper && this.$scope.inOffsetEditMode) {
                this.exitOffsetEditMode(false);
            }
            while (this.unsubCallbacks.length) {
                const unsub = this.unsubCallbacks.pop();
                unsub();
            }
        });

        this.unsubCallbacks.push(
            this.lidarHelper.subscribe('autoSourceSwitch', (_lidarHelper, dataSource) => {
                this.$scope.dataSource.source = dataSource;
            }),
        );

        this.unsubCallbacks.push(
            this.lidarHelper.subscribe('visibilitySet', (_lidarHelper, visibility) => {
                this.$scope.isLidarVisible = visibility;
            }),
        );

        this.unsubCallbacks.push(
            this.lidarHelper.subscribe('lidarLoadStart', () => {
                this.$scope.isLidarLoading = true;
                this.$scope.$apply();
            }),
        );

        this.unsubCallbacks.push(
            this.lidarHelper.subscribe('lidarLoadEnd', (_lidarHelper, { success }) => {
                this.$scope.isLidarLoading = false;
                this.$scope.isLidarAvailable = success;
                this.$scope.$apply();
            }),
        );
    }

    hasFeature(feat) {
        return user.hasFeature(feat);
    }

    isLidarVisible() {
        const renderer = this.dispatcher.renderer;
        return renderer && renderer.lidarHelper && renderer.lidarHelper.getVisibility();
    }

    isOffsetModeDisabled() {
        return this.$scope.isLidarLoading || !this.$scope.isLidarVisible || !this.$scope.isLidarAvailable;
    }

    enterOffsetEditMode() {
        analytics.track('lidar.enterOffsetEditMode', {
            project_id: this.design.project_id,
            design_id: this.design.design_id,
            team_id: $rootScope.user().team_id,
        });
        if (!this.dispatcher.renderer || !this.dispatcher.renderer.lidarHelper) {
            return;
        }
        const offsets = this.$scope.editLidarOffsets;
        offsets.editLidarOffsetX = this.design.geometry.lidar_settings.lidar_offset_x;
        offsets.editLidarOffsetY = this.design.geometry.lidar_settings.lidar_offset_y;
        offsets.editLidarOffsetZ = this.design.geometry.lidar_settings.lidar_offset_z;
        this.$scope.inOffsetEditMode = true;
        this.dispatcher.renderer.lidarHelper.setLidarOffsetEditMode(true);
    }

    exitOffsetEditMode(save) {
        analytics.track('lidar.exitOffsetEditMode', {
            action: save ? 'save' : 'cancel',
            project_id: this.design.project_id,
            design_id: this.design.design_id,
            team_id: $rootScope.user().team_id,
        });
        if (save) {
            const offsets = this.$scope.editLidarOffsets;
            this.design.geometry.lidar_settings.lidar_offset_x = offsets.editLidarOffsetX;
            this.design.geometry.lidar_settings.lidar_offset_y = offsets.editLidarOffsetY;
            this.design.geometry.lidar_settings.lidar_offset_z = offsets.editLidarOffsetZ;
        }
        this.$scope.inOffsetEditMode = false;
        this.dispatcher.renderer.lidarHelper.setLidarOffsetEditMode(false);
        this.updateSettings(!save);
    }

    updateOffset() {
        const offsets = this.$scope.editLidarOffsets;
        this.dispatcher.renderer.lidarHelper.setLidarEditOffset(
            offsets.editLidarOffsetX,
            offsets.editLidarOffsetY,
            offsets.editLidarOffsetZ,
        );
        this.updateSettings(true);
    }

    updateDataSource() {
        analytics.track('lidar.sourceSwitch', {
            source: SOURCE_CFG[this.$scope.dataSource.source].fullName,
            project_id: this.design.project_id,
            design_id: this.design.design_id,
            team_id: $rootScope.user().team_id,
        });
        this.design.geometry.lidar_settings.data_source = this.$scope.dataSource.source;
        this.updateSettings();
    }

    updateDisplayMode(mode) {
        analytics.track('lidar.updateDisplayMode', {
            mode,
            project_id: this.design.project_id,
            design_id: this.design.design_id,
            team_id: $rootScope.user().team_id,
        });
        this.updateSettings(true);
    }

    updateSettings(displayOnly = false) {
        this.stateHandler.updateQueue.schedule(this.design);

        if (this.dispatcher.renderer && this.dispatcher.renderer.lidarHelper) {
            if (displayOnly) {
                this.dispatcher.renderer.lidarHelper.forceRedraw();
            } else {
                this.triggerLidarLoad();
            }
        }
    }

    triggerLidarLoad() {
        this.dispatcher.renderer.lidarHelper.updateData(true);
    }

    toggleLidar() {
        if (!this.dispatcher.renderer || !this.dispatcher.renderer.lidarHelper) {
            return;
        }
        const newVisibility = !this.dispatcher.renderer.lidarHelper.getVisibility();
        analytics.track('lidar.toggleLidar', {
            toggle: newVisibility ? 'on' : 'off',
            project_id: this.design.project_id,
            design_id: this.design.design_id,
            team_id: $rootScope.user().team_id,
        });
        this.dispatcher.renderer.lidarHelper.setVisibility(newVisibility);
    }
}

export class DesignerPreferencesCtrl {
    constructor(acConfigs, dispatcher, design, moduleCharacterizations, $scope) {
        'ngInject';

        this.currentUser = _.clone(Authenticator.user());
        this.designerSettings = this.currentUser.preferences.designer;
        this.acConfigs = acConfigs;

        this.dispatcher = dispatcher;
        this.design = design;
        this.moduleCharacterizations = moduleCharacterizations;

        $scope.$watch('prefsCtrl.designerSettings', () => this.updateLayoutSettings(), true);
    }

    hasFeature(feat) {
        return user.hasFeature(feat);
    }

    updateLayoutSettings() {
        _.extend(Authenticator.user().preferences.designer, this.designerSettings);
        const renderer = this.dispatcher.renderer;

        if (renderer) {
            // this is a hacky, but effective way to force the labels to update
            // I'm glad the render is not as slow as it used to be
            renderer.clearDesign(this.design);
            renderer.renderDesign(this.design, { updateViewport: false });
        } else {
            _.delay(() => this.updateLayoutSettings(), 250);
        }
    }

    update() {
        this.btnLoading = true;
        const notify = Messager.load('Updating your preferences...');

        this.currentUser
            .$updateDesignerPreferences()
            // eslint-disable-next-line no-shadow
            .then((user) => {
                notify.success('Update Successful');
                this.btnLoading = false;
                _.extend(Authenticator.user(), user);
                this.designerSettings = this.currentUser.preferences.designer;

                this.updateLayoutSettings();
            })
            .catch(() => {
                this.btnLoading = false;
                notify.error('Update failed');
            });
    }
}

export class DesignMenuCtrl {
    constructor(design) {
        'ngInject';

        this.design = design;
        this.revisions = [];
        this.team_id = $rootScope.user().team_id;
        Design.query({ project_id: design.project.project_id }).$promise.then((designs) => {
            this.revisions = designs.filter((d) => !d.to_delete);
        });
    }

    async createRevision() {
        const notify = Messager.load('Creating new design');

        const takenNames = this.revisions.map((des) => des.description);
        const description = createUniqueDescription(this.design.description, takenNames);

        const design = new Design({
            description,
            clone_design_id: this.design.design_id,
            project_id: this.design.project_id,
        });

        try {
            const newDesign = await design.$save();
            notify.success('Design created successfully');
            $state.transitionTo('designer.design.field_segments', { design_id: newDesign.design_id });
        } catch (e) {
            notify.error('Design could not be created');
        }
    }
}

export class WorldMenuCtrl {
    constructor(dispatcher) {
        'ngInject';

        this.dispatcher = dispatcher;
        this.internalClipboard = dispatcher.internalClipboard;
    }
    paste() {
        this.dispatcher.activatePasteMode();
    }

    hasComponentInClipboard() {
        return this.internalClipboard.isNotEmpty();
    }

    isOnMac() {
        return isMacLike();
    }
}

export const WorldContextMenu = {
    templateUrl: require('helioscope/app/designer/partials/world_context.html'),
    controller: WorldMenuCtrl,
    controllerAs: 'wdMenu',
};

export class BulkActionsPanelCtrl {
    constructor(dispatcher, $scope) {
        'ngInject';

        this.dispatcher = dispatcher;

        this.fieldSegments = [];
        this.keepouts = [];
        this.entityPremades = [];

        this.outer_setback = 0;
        this.reference_height = 0;

        this.text = {
            onlyKeepouts: {
                title: 'keepouts selected',
                description: 'Adjust their setback and height, or simply rotate, copy and paste.',
            },
            default: {
                title: 'Multiple objects selected',
                description: 'You may move, rotate, copy, paste or delete the objects.',
            },
        };
        this.redirectToDefault();

        this.startControl();

        $scope.$on('$destroy', () => this.stopControl());

        this.splitSelectedEntities();

        this.checkProperties();
    }

    startControl() {
        this.unsubscribers = [
            this.dispatcher.subscribe('entitySelectionChanged', () => this.handleEntitySelectionChanged()),
            this.dispatcher.subscribe('bulkEntityChanged', () => this.checkProperties()),
        ];
    }

    stopControl() {
        for (const unsub of this.unsubscribers) {
            unsub();
        }
        this.unsubscribers = null;
    }

    redirectToDefault() {
        if (this.dispatcher.selectedEntities.size === 0) {
            $state.go('designer.design.field_segments');
        }
    }

    splitSelectedEntities() {
        this.fieldSegments = [];
        this.keepouts = [];
        this.entityPremades = [];
        this.dispatcher.selectedEntities.forEach((entity) => {
            if (entity instanceof FieldSegment) {
                this.fieldSegments.push(entity);
            } else if (entity instanceof Keepout) {
                this.keepouts.push(entity);
            } else if (entity instanceof EntityPremade) {
                this.entityPremades.push(entity);
            }
        });
    }

    onlyKeepoutsSelected() {
        return this.keepouts.length === this.dispatcher.selectedEntities.size;
    }

    getTitle() {
        let title = this.text.default.title;
        if (this.onlyKeepoutsSelected()) {
            title = `${this.keepouts.length} ${this.text.onlyKeepouts.title}`;
        }
        return title;
    }

    getDescription() {
        let description = this.text.default.description;
        if (this.onlyKeepoutsSelected()) {
            description = this.text.onlyKeepouts.description;
        }
        return description;
    }

    updateSetbacks({ dispatcher = this.dispatcher, outerSetback } = {}) {
        if (this.dispatcher.selectedEntities.size === 0 || outerSetback === undefined) {
            return;
        }

        if (outerSetback > DESIGNER_CONSTANTS.MAX_SETBACK) {
            outerSetback = DESIGNER_CONSTANTS.MAX_SETBACK;
            this.outer_setback = outerSetback;
        }

        bulkActions.update(dispatcher, this.keepouts, { outer_setback: outerSetback });
    }

    updateHeights({ dispatcher = this.dispatcher, referenceHeight } = {}) {
        if (this.dispatcher.selectedEntities.size === 0 || referenceHeight === undefined) {
            return;
        }

        if (referenceHeight > DESIGNER_CONSTANTS.MAX_HEIGHT) {
            referenceHeight = DESIGNER_CONSTANTS.MAX_HEIGHT;
            this.reference_height = referenceHeight;
        }

        bulkActions.update(dispatcher, this.keepouts, { reference_height: referenceHeight });
    }

    checkUniformProperty(entities, property) {
        if (entities.length === 0) {
            this[property] = undefined;
            return;
        }

        const referenceValue = entities[0][property];
        const allSameValue = entities.every((entity) => entity[property] === referenceValue);

        if (allSameValue) {
            this[property] = referenceValue;
        } else {
            this[property] = undefined;
        }
    }

    checkProperties() {
        if (this.onlyKeepoutsSelected()) {
            this.checkUniformProperty(this.keepouts, 'outer_setback');
            this.checkUniformProperty(this.keepouts, 'reference_height');
        }
    }

    handleEntitySelectionChanged() {
        this.splitSelectedEntities();
        this.checkProperties();
    }
}

export class SelectionContextMenuCtrl extends DesignActionsMixin {
    constructor(dispatcher) {
        'ngInject';

        super(dispatcher);
        this.dispatcher = dispatcher;
        this.internalClipboard = dispatcher.internalClipboard;
    }

    copySelection() {
        this.dispatcher.copySelectedEntitiesToClipboard();
    }

    pasteSelection() {
        this.dispatcher.activatePasteMode();
    }

    deleteSelection() {
        this.deleteSelectedEntities();
    }

    hasComponentInClipboard() {
        return this.internalClipboard.isNotEmpty();
    }

    isOnMac() {
        return isMacLike();
    }

    rotate() {
        this.dispatcher.onRendererReady(() => {
            this.dispatcher.renderer.activateInteractTool({
                tool: 'InteractToolRotation',
            });
        });
    }
}

export const SelectionContextMenu = {
    templateUrl: require('helioscope/app/designer/partials/selection_context.html'),
    controller: SelectionContextMenuCtrl,
    controllerAs: 'slMenu',
};

// The design render needs a very basic controller so it can access the dispatcher
// It was previously using the DesignCtrl which was doing a lot of extra work each time it went to do a design render
// This became clear once lidar loading was moved to the DesignCtrl as it was trying to trigger lidar loading
export class DesignRenderCtrl {
    constructor(dispatcher) {
        'ngInject';

        this.dispatcher = dispatcher;
    }
}
